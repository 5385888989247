import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ADD_DISCOUNT_POPUP_PAGE,
  SET_BARCODEPRINTPAGEPOPUP,
  SET_ROUTE,
  SET_SELECTEDHEADER,
  SET_SETTINGS_POPUP,
} from "../../Features/rightBarHeader";
import {
  SET_CLOSING_CASH,
  SET_CLOSING_DATE,
  SET_DELTA,
  SET_EXPENSE_DATA,
  SET_SALESINFO,
  SET_SALESINFO_BY_CATEGORY,
  SET_SALESINFO_BY_PAYMENT_TYPE,
  SET_SALESREPORTPOPUP,
} from "../../Features/salesReport";
import { BsFillGearFill } from "react-icons/bs";
import SettingsPopupPage from "../../Pages/SettingsPopupPage";
import { RootState } from "../..";
import {
  SET_SELECTED_ORDER,
  SET_CASH_PAID,
  SET_IS_ORDER_READY,
  SET_CARD_PAID,
  SET_CREDIT_PAID,
} from "../../Features/cart";
import { SET_CUSTOMER_DATA } from "../../Features/customer";
import { Modal, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddDiscountPopupPage from "../../Pages/AddDiscountPopupPage";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { BaseService, createNewBaseService } from "../../assets/BaseService";
import PrintSalesReport from "../Footer/PrintSalesReport";
import moment from "moment";
import { objSum, objSum2, sumExpenses } from "../Footer/SalesReportUtils";
import { useIsFirstRender } from "usehooks-ts";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";
import { SET_DARK_MODE } from "../../Features/leftBarHeader";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const HeaderDropdown: React.FC = () => {
  // @ts-ignore
  const classes = useStyles();
  const [closeTill, setCloseTill] = useState<boolean>(false);
  const [closingCash, setClosingCash] = useState<number | undefined>(undefined);
  const [closingCashTrigger, setClosingCashTrigger] = useState<number>(0);
  const [estimatedCashInHand, setEstimatedCashInHand] = useState<number>(0);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const printButton = useSelector(
    (state: RootState) => state.salesReport.salesReportPopUp
  );
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const dispatch = useDispatch();
  const [items, setItems] = useState<ItemType<MenuItemType>[] | undefined>(
    undefined
  );
  const userId = useSelector(
    (state: RootState) => state.leftHeader.response_id
  );
  const userName = localStorage.getItem("username")
  const salesReport = useSelector((state: RootState) => state.salesReport);
  const isFirst = useIsFirstRender();
  const [darkModeCheck, setDarkModeCheck] = useState<boolean>(false);

  const NewBaseService = createNewBaseService(pos_config)


  useEffect(() => {
    darkModeCheck
      ? dispatch(SET_DARK_MODE("Dark Mode"))
      : dispatch(SET_DARK_MODE("Light Mode"));
  }, [darkModeCheck]);

  const getDataFromAPI = async () => {
    await BaseService.post(
      `/dynamic/query/get-orders-count-by-category?from=${salesReport.openingDate
        ? salesReport.openingDate.toISOString()
        : new Date().toISOString()
      }&till=${new Date().toISOString()}&userId=${userId}&status=dispatched`,
      {},
      {}
    ).then(async (res) => {
      console.log("order-data", res.data.data);
      setCloseTill(false);
      dispatch(SET_SALESINFO_BY_CATEGORY(res.data.data));

      await BaseService.post(
        `/dynamic/query/get-orders-count-by-payment-type?from=${salesReport.openingDate
          ? salesReport.openingDate.toISOString()
          : new Date().toISOString()
        }&till=${new Date().toISOString()}&userId=${userId}&status=dispatched`,
        {},
        {}
      ).then(async (res) => {
        console.log("order-data", res.data.data);
        dispatch(SET_SALESINFO_BY_PAYMENT_TYPE(res.data.data));

        await BaseService.post(
          `/dynamic/query/get-orders-count-by-products?from=${salesReport.openingDate
            ? salesReport.openingDate.toISOString()
            : new Date().toISOString()
          }&till=${new Date().toISOString()}&userId=${userId}&status=dispatched`,
          {},
          {}
        ).then(async (res) => {
          console.log("order-data", res.data.data);
          dispatch(SET_SALESINFO(res.data.data));

          await BaseService.post(
            "/dynamic/get",
            {
              collection: "expenses",
              filter: {
                createdBy: userId,
                createdAt: {
                  $gte: salesReport.openingDate
                    ? salesReport.openingDate.toISOString()
                    : new Date().toISOString(),
                  $lte: new Date().toISOString(),
                },
              },
            },
            {}
          )
            .then((res) => {
              console.log(res.data.data);
              dispatch(SET_EXPENSE_DATA(res.data.data));
            })
            .then(() => {
              setClosingCashTrigger(closingCashTrigger + 1);
            });
        });
      });
    });
  };

  const ClosingCashPreFunction = () => {
    if (
      salesReport.salesInfoByPaymentType &&
      salesReport.salesInfoByPaymentType.filter(
        (val: any) => val.paymentType === "Cash"
      ).length > 0 &&
      salesReport.expenseData
    ) {
      return (
        salesReport.salesInfoByPaymentType.filter(
          (val: any) => val.paymentType === "Cash"
        )[0]?.totalOrderAmount - sumExpenses(salesReport.expenseData)
      );
    } else if (
      salesReport.salesInfoByPaymentType &&
      salesReport.salesInfoByPaymentType.filter(
        (val: any) => val.paymentType === "Cash"
      ).length > 0
    ) {
      return salesReport.salesInfoByPaymentType.filter(
        (val: any) => val.paymentType === "Cash"
      )[0]?.totalOrderAmount;
    } else if (salesReport.expenseData) {
      return 0 - sumExpenses(salesReport.expenseData);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    let index: number = 1;

    let items2: MenuProps["items"] = [
      {
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage"));
            }}
          >
            Pos Screen
          </div>
        ),
      },
    ];

    index++;

    items2 &&
      pos_config &&
      pos_config.enable_kitchen_display_module &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/KDSPage"));
            }}
          >
            KDS Screen
          </div>
        ),
      });

    index++;

    items2 &&
      pos_config.enable_kitchen_display_module &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/KDSOrders"));
            }}
          >
            KDS Orders
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/AddExpensePage"));
            }}
          >
            Add Expense
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/OrderListingPage"));
            }}
          >
            Order Listing Page
          </div>
        ),
      });

    index++;

    items2 &&
      pos_config &&
      pos_config.enable_reservation_module &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/ReservationPage"));
            }}
          >
            Reservation
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ROUTE("/PosPage/PriceCheckerPage"));
            }}
          >
            Price Checker
          </div>
        ),
      });

    index++;

    items2 &&
      pos_config &&
      pos_config.enable_barcode_printing_module &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_BARCODEPRINTPAGEPOPUP(true));
            }}
          >
            Barcode Print Screen
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_SETTINGS_POPUP(true));
            }}
          >
            Settings
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={() => {
              dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(true));
            }}
          >
            Add Discount
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={async () => {
              await getDataFromAPI();

              dispatch(SET_SELECTED_ORDER(null));
              dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
              dispatch(SET_CASH_PAID(0));
              dispatch(SET_CARD_PAID(0));
              dispatch(SET_CREDIT_PAID(0));
              dispatch(SET_CUSTOMER_DATA(undefined));

              pos_config && pos_config.customer_tab_as_default_tab
                ? dispatch(SET_SELECTEDHEADER("Delivery"))
                : dispatch(SET_SELECTEDHEADER("Pickup"));

              pos_config && pos_config.enable_open_till_close_till === true
                ? setCloseTill(true)
                : dispatch(SET_ROUTE(""));
            }}
          >
            Closing
          </div>
        ),
      });

    index++;

    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div
            onClick={async () => {
              await BaseService.post("/signout", {})
                .then((res) => {
                  console.log("logout response", res.data);
                })
                .catch((err) => {
                  console.log("logout error", err);
                });
              localStorage.removeItem("Login Time")
              localStorage.removeItem("name");
              localStorage.removeItem("username");
              localStorage.removeItem("access_token");
              localStorage.removeItem("branch_id");
              localStorage.removeItem("userID")
              dispatch(SET_SELECTED_ORDER(null));
              dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
              dispatch(SET_CASH_PAID(0));
              dispatch(SET_CARD_PAID(0));
              dispatch(SET_CREDIT_PAID(0));
              dispatch(SET_CUSTOMER_DATA(undefined));

              pos_config && pos_config.customer_tab_as_default_tab
                ? dispatch(SET_SELECTEDHEADER("Delivery"))
                : dispatch(SET_SELECTEDHEADER("Pickup"));

              dispatch(SET_ROUTE(""));
            }}
          >
            Logout
          </div>
        ),
      });

    index++;
    items2 &&
      items2.push({
        key: index.toString(),
        label: (
          <div>
            <span className="">Change Theme</span>
            <Switch
              checked={darkModeCheck}
              onChange={() => setDarkModeCheck(!darkModeCheck)}
            />
          </div>
        ),
      });

    setItems(items2);
  }, [pos_config, darkMode]);

  console.log("Date format", new Date().toISOString());

  const RevenueGenerator = () => {
    if (salesReport.salesInfo && salesReport.expenseData) {
      return (
        objSum2(salesReport.salesInfo) - sumExpenses(salesReport.expenseData)
      );
    } else if (salesReport.salesInfo) {
      return objSum2(salesReport.salesInfo);
    } else if (salesReport.expenseData) {
      return 0 - sumExpenses(salesReport.expenseData);
    } else {
      return 0;
    }
  };

  const handleReport = async (e: any) => {
    e.preventDefault();

    const closingDate = new Date();
    dispatch(SET_CLOSING_DATE(closingDate));
    dispatch(SET_CLOSING_CASH(closingCash !== undefined ? closingCash : 0));
    const delta =
      closingCash !== undefined
        ? closingCash - estimatedCashInHand
        : 0 - estimatedCashInHand;
    dispatch(SET_DELTA(delta));

    await NewBaseService.patch(
      "/dynamic/update",
      {
        collection: "openclosinglog",
        filter: {
          createdBy: userName,
          isClosed: false,
        },
        data: {
          userId: userName,
          closingDate: closingDate.toISOString(),
          closingAmount: closingCash !== undefined ? closingCash : 0,
          revenue: salesReport.salesInfo ? objSum2(salesReport.salesInfo) : 0,
          cash:
            salesReport.salesInfoByPaymentType &&
            salesReport.salesInfoByPaymentType[0]?.totalOrderAmount,
          card:
            salesReport.salesInfoByPaymentType &&
            salesReport.salesInfoByPaymentType[1]?.totalOrderAmount,
          expenses: salesReport.expenseData
            ? sumExpenses(salesReport.expenseData)
            : 0,
          submissions: 0,
          delta: delta,
          isClosed: true,
        },
      },
      {}
    ).then((res) => {
      console.log(res.data.data);
      dispatch(SET_SALESREPORTPOPUP(true));
    });
  };

  useEffect(() => {
    if (!isFirst) {
      console.log(
        "ClosingCashFunction - 2",
        ClosingCashPreFunction(),
        salesReport.openingCash !== undefined ? salesReport.openingCash : 0
      );
      let val: number =
        ClosingCashPreFunction() +
        (salesReport.openingCash !== undefined ? salesReport.openingCash : 0);
      console.log(val);
      setEstimatedCashInHand(val);
      setClosingCash(val);
    }
  }, [salesReport.openingCash, closingCashTrigger]);

  return (
    <div>
      <Dropdown menu={{ items }} placement="bottomRight">
        <div className="text-white text-[20px] cursor-pointer px-[20px] py-[10px]">
          <BsFillGearFill />
        </div>
      </Dropdown>

      <SettingsPopupPage />
      <AddDiscountPopupPage />

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={closeTill}
      >
        <div
          className={`bg-[${!darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
            }] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
              }] text-[24px] font-[700] text-center`}
          >
            Enter Closing Cash
          </div>
          <div className="flex w-full h-auto pt-4 flex-col items-start justify-center gap-4">
            <div className="flex items-center justify-start gap-4">
              <div>Opening Cash:</div>
              <div className="italic text-slate-500">
                Rs.{salesReport.openingCash}
              </div>
            </div>
            <div className="flex items-center justify-start gap-4">
              <div>Opening Date:</div>
              <div className="italic text-slate-500">
                {salesReport.openingDate &&
                  salesReport.openingDate instanceof Date
                  ? salesReport.openingDate.toLocaleDateString("en-GB", {
                    month: "long",
                    day: "numeric",
                    year: "2-digit",
                    hour: "2-digit",
                    hour12: true,
                    minute: "2-digit",
                  })
                  : "Opening Date not set"}
              </div>
            </div>
            <div className="flex items-center justify-start gap-4">
              <div>Expenses:</div>
              <div className="italic text-slate-500">
                Rs.
                {salesReport.expenseData
                  ? sumExpenses(salesReport.expenseData)
                  : 0}
              </div>
            </div>
            <div className="flex items-center justify-start gap-4">
              <div>Estimated Closing Cash:</div>
              <div className="italic text-slate-500">
                Rs.
                {estimatedCashInHand}
              </div>
            </div>
            <div className="flex items-center justify-start gap-4">
              <div>Total Sales:</div>
              <div className="italic text-slate-500">
                Rs.{salesReport.salesInfo ? objSum2(salesReport.salesInfo) : 0}
              </div>
            </div>
          </div>
          <form
            action=""
            onSubmit={handleReport}
            className="mt-[32px] gap-4 flex items-center justify-center flex-col"
          >
            <input
              className="w-full h-[56px] px-4 rounded-lg"
              type="number"
              value={closingCash !== undefined ? closingCash : 0}
              autoFocus
              onChange={(e) => setClosingCash(parseInt(e.target.value))}
              placeholder="Closing Cash"
              required
            />

            <button
              type="submit"
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[16px] font-[700]`}
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
      {printButton && (
        <div
          id="invoice"
          className="fixed top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 w-full bg-white z-10 h-[100%] overflow-scroll"
        >
          <PrintSalesReport />
        </div>
      )}
    </div>
  );
};

export default HeaderDropdown;
