import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { Option, Orderdaum, Root, Root2 } from "../../../Interfaces/IOrderData";
import Modal from "@mui/material/Modal";
import { debounce } from "lodash";
import { createNewBaseService } from "../../../assets/BaseService";
import {
  SET_BUTTON_CLICKED,
  SET_CART_ORDER,
  SET_CHECKOUT_POPUP,
  SET_MOBILE_SEARCH,
  SET_ORDER_JSON,
  SET_SELECTED_ORDER,
} from "../../../Features/cart";
import { AddOn, CartOrder, Value } from "../../../Interfaces/ICartOrder";
import { SET_CUSTOMER_DATA } from "../../../Features/customer";
import { OrderDetails } from "../../../Interfaces/IOrderDetails";
import { FaInfoCircle, FaMoneyBillWave, FaSync } from "react-icons/fa";

import FeedbackModal from "./Modal/FeedbackModal";
import ComplaintModal from "./Modal/ComplaintModal";
import AssignRiderModal from "./Modal/AssignRiderModal";
import { IoMdClose } from "react-icons/io";

const OrdersList = () => {
  const dispatch = useDispatch();

  const cart = useSelector((state: RootState) => state.cart);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const branchName = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );
  const NewBaseService = createNewBaseService(pos_config);
  const leftHeader = useSelector((state: RootState) => state.leftHeader);
  const customer = useSelector((state: RootState) => state.customer);

  const [orders, setOrders] = useState<Root>([]);
  const [searchedOrders, setSearchedOrders] = useState<Root>([]);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] =
    useState<Root2 | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [payClicked, setPayClicked] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const ordersPerPage = 20;
  const [totalOrders, setTotalOrders] = useState<number>(0)

  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  const [complainModalOpen, setComplainModalOpen] = useState<boolean>(false);
  const [assignRiderModalOpen, setAssignRiderModalOpen] = useState<boolean>(false);

  const isButtonClicked = useSelector((state: RootState) => state.cart.isButtonClicked);


  const viewOrder = useSelector((state: RootState) => state.cart.viewOrder);



  const fetchOrders = useCallback(async (pageNumber: number) => {
    setIsLoading(true);

    try {
      let queryParams = new URLSearchParams({
        collection: "orders",
        skip: ((pageNumber - 1) * ordersPerPage).toString(),
        limit: ordersPerPage.toString(),
        branch: branchName
      });

      if (cart.filteredOrder !== "None") {
        queryParams.append("POSStatus", cart.filteredOrder.toLowerCase());
      }

      if (cart.mobileSearch) {
        queryParams.append("CustomerMobile", cart.mobileSearch);
      }

      if (cart.orderIdSearch) {
        queryParams.append("OrderID", cart.orderIdSearch);
      }

      if (cart.dateRange.startDate && cart.dateRange.endDate) {
        queryParams.append("startDate", new Date(cart.dateRange.startDate).toISOString());
        queryParams.append("endDate", new Date(cart.dateRange.endDate).toISOString());
      }

      const res = await NewBaseService.get(
        `/dynamic/query/get-all-orders-with-details?${queryParams.toString()}`
      );

      setOrders(res.data.data);
      setSearchedOrders(res.data.data);
      setTotalOrders(res.data.recordcount);
    } catch (err) {
      console.error("Orders get error", err);
    } finally {
      setIsLoading(false);
      dispatch(SET_BUTTON_CLICKED(false));
    }
  }, [branchName, cart.filteredOrder, cart.mobileSearch, currentPage, cart.orderIdSearch, cart.dateRange]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cart.filteredOrder]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cart.mobileSearch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cart.orderIdSearch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [cart.dateRange]);


  const convertOrderDetailsToCartOrder = (orderDetails: Root2): CartOrder => {
    if (!orderDetails.orderdata || !Array.isArray(orderDetails.orderdata)) {
      return [];
    }
    return orderDetails.orderdata.map((orderItem: Orderdaum) => {
      const value: Value[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          properties: [
            {
              ID: option.OptionID,
              Name: option.OptionGroupName,
              Price: parseFloat(option.Price),
              OriginalPrice: parseFloat(option.Price),
              Discount: 0,
              IsActive: true,
            },
          ],
          price: parseFloat(option.Price),
          isMultiple: false,
        })) || [];

      const addOns: AddOn[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          price: parseFloat(option.Price),
          quantity: parseInt(option.Quantity),
          objGroupName: option.OptionGroupName,
          optionID: option.OptionID,
        })) || [];

      return {
        itemName: orderItem.ItemName,
        value,
        total: parseFloat(orderItem.Price),
        itemPrice: parseFloat(orderItem.Price),
        addOns,
        batchNumber: orderItem.batchNumber,
        quantity: parseInt(orderItem.Quantity),
        categoryName: orderItem.Category,
        MinimumDelivery: orderItem.minimumDelivery,
        sizeID: orderItem.SizeID,
        itemID: orderItem.ItemID,
        orderTotal: parseFloat(orderItem.Price),
        index: 0,
        itemStatus: orderItem.Status,
        isComingFromAPI: true,
      };
    });
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [isButtonClicked, currentPage]);


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleFeedback = (item: any) => {
    setSelectedOrderId(item.ID);
    setSelectedOrderDetails(item);
    setFeedbackModalOpen(true);
  };

  const handleComplain = (item: any) => {
    setSelectedOrderId(item.ID);
    setSelectedOrderDetails(item);
    setComplainModalOpen(true);
  };

  const handleAssignRider = (item: any) => {
    setSelectedOrderId(item.ID);
    setSelectedOrderDetails(item);
    setAssignRiderModalOpen(true);
  };

  const createOrderJson = (status: string, kdsStatus: string): OrderDetails => {
    const userName = localStorage.getItem("username")
    return {
      branch: cart.loginData.branchName,
      code: "",
      platform: "POS",
      name: cart.name,
      status: status,
      orderType: leftHeader.selectedHeader,
      area: "Test area",
      RiderName: "",
      RiderPhone: "",
      AssignedRiderID: "",
      DeliveryStatus: "",
      collectedby: status === "dispatched" ? "" : userName,
      tax: cart.taxAmount,
      voucher: cart.voucher,
      discountamount: cart.discount,
      orderamount: cart.cartTotal,
      paymenttype: cart.paymentMethod,
      paymentRemarks: cart.paymentRemarks,
      cashAmount: cart.payment.cashPaid,
      cardAmount: cart.payment.cardPaid,
      creditAmount: cart.payment.creditPaid,
      creditorName: cart.creditorName,
      totalamount: cart.discountedAndTaxedAmount,
      taxamount: cart.taxAmount,
      deliverycharges: 0,
      kdsStatus: "Pending",
      customerFullName: customer.data ? customer.data.Name : null,
      customerPhone: customer.data ? customer.data.Mobile : null,
      customerAddress: customer.data ? customer.data.Address : null,
      customerCity: customer.data ? customer.data.City : null,
      customerLatitude: null,
      customerLongitude: null,
      customerOtherAddresses: customer.data
        ? customer.data.otherAddresses
        : null,
      orderdata: cart.order.map((obj: any) => ({
        productName: obj.itemName,
        refCode: obj.itemID,
        categoryName: obj.categoryName,
        itemStatus: obj.itemStatus || "Pending",
        discountGiven: 0,
        minimumDelivery: obj.MinimumDelivery,
        itemImage:
          "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
        options: obj.addOns.map((temp: any) => ({
          OptionID: temp.optionID,
          OptionName: temp.name,
          OptionGroupName: temp.objGroupName,
          Price: temp.price,
          Quantity: temp.quantity,
        })),
        quantity: obj.quantity,
        price: obj.total,
        totalProductPrice: obj.orderTotal,
        SizeID: obj.sizeID,
      })),
      Remarks: "",
      category: "",
    };
  };

  const handlePay = (item: any) => {
    if (item && item.POSStatus === "hold") {
      dispatch(SET_SELECTED_ORDER(item));
      dispatch(
        SET_CUSTOMER_DATA({
          Name: item.CustomerName,
          Mobile: item.CustomerMobile || "",
          Email: item.Email || "",
          ID: item.CustomerID,
          IsActive: "",
          Address: "",
          City: item.City,
          MemberPoints: "",
          customerRating: 0,
          otherAddresses: [],
          orders: [],
          complains: [],
          Created: "",
          updatedAt: "",
          Orders: "",
          __v: 0,
        })
      );
      const convertedOrder = convertOrderDetailsToCartOrder(item);
      dispatch(SET_CART_ORDER(convertedOrder));
      setPayClicked(true);
    }
  };

  const Pagination = () => {
    // Don't render pagination if no data
    if (totalOrders === 0) return null;
  
    const totalPages = Math.ceil(totalOrders / ordersPerPage);
    
    // Validate current page bounds
    if (currentPage < 1) {
      setCurrentPage(1);
      return null;
    }
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
      return null;
    }
  
    // Calculate visible page numbers
    const getVisiblePages = () => {
      const delta = 2; // Number of pages to show before/after current page
      const pages = [];
      
      // Always show first page
      pages.push(1);
      
      // Calculate range around current page
      for (let i = Math.max(2, currentPage - delta); 
           i <= Math.min(totalPages - 1, currentPage + delta); 
           i++) {
        pages.push(i);
      }
      
      // Always show last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
  
      // Add ellipsis indicators
      const withEllipsis = [];
      let prev = 0;
      
      for (const page of pages) {
        if (prev && page - prev > 1) {
          withEllipsis.push('...');
        }
        withEllipsis.push(page);
        prev = page;
      }
  
      return withEllipsis;
    };
  
    return (
      <div className="flex flex-col items-center gap-4 mt-8 mb-4">
        <div className="flex items-center gap-3">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md font-medium transition-all duration-200 
              ${currentPage === 1 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : darkMode
                  ? 'bg-gray-700 text-white hover:bg-gray-600 active:bg-gray-800'
                  : 'bg-white text-gray-700 hover:bg-gray-50 active:bg-gray-100 border border-gray-300'
              }`}
          >
            ← Previous
          </button>
  
          <div className="flex items-center gap-2">
            {getVisiblePages().map((page, index) => 
              page === '...' ? (
                <span key={`ellipsis-${index}`} className="text-gray-500">...</span>
              ) : (
                <button
                  key={page}
                  onClick={() => handlePageChange(page as number)}
                  className={`px-4 py-2 rounded-md font-medium transition-all duration-200 
                    ${currentPage === page
                      ? darkMode 
                        ? 'bg-blue-600 text-white'
                        : 'bg-blue-500 text-white'
                      : darkMode
                        ? 'bg-gray-700 text-white hover:bg-gray-600 active:bg-gray-800'
                        : 'bg-white text-gray-700 hover:bg-gray-50 active:bg-gray-100 border border-gray-300'
                    }`}
                >
                  {page}
                </button>
              )
            )}
          </div>
  
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
            className={`px-4 py-2 rounded-md font-medium transition-all duration-200 
              ${currentPage >= totalPages
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : darkMode
                  ? 'bg-gray-700 text-white hover:bg-gray-600 active:bg-gray-800'
                  : 'bg-white text-gray-700 hover:bg-gray-50 active:bg-gray-100 border border-gray-300'
              }`}
          >
            Next →
          </button>
        </div>
        
        <div className="text-sm text-gray-500">
          Page {currentPage} of {totalPages} ({totalOrders} total items)
        </div>
      </div>
    );
  };
  



  useEffect(() => {
    if (payClicked) {
      const order: OrderDetails = createOrderJson("dispatched", "Served");
      dispatch(SET_ORDER_JSON(order));
      dispatch(SET_CHECKOUT_POPUP(true));
      setPayClicked(false);
    }
  }, [payClicked]);

  // const debouncedSearch = useMemo(
  //   () =>
  //     debounce((searchTerm: string) => {
  //       setSearchedOrders(
  //         orders.filter((item: Root2) =>
  //           item.code.toString().includes(searchTerm)
  //         )
  //       );
  //     }, 300),
  //   [orders]
  // );

  // useEffect(() => {
  //   debouncedSearch(cart.searchedOrder);
  //   return () => debouncedSearch.cancel();
  // }, [cart.searchedOrder, debouncedSearch]);


  const OrderItem = React.memo(({ item }: { item: Root2 }) => (
    <div className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"} 
                  border ${darkMode ? "border-gray-700" : "border-gray-200"} 
                  rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-4`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className="flex items-center">
            <h3 className="text-lg font-bold">#{item.code}</h3>
            <div className="flex items-center ml-3 space-x-2">
              <button
                className={`p-1.5 rounded-md transition-colors duration-200 hover:scale-105 transform
                  ${darkMode ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'}`}
                onClick={() => {
                  setSelectedOrderDetails(item);
                  setOpenOrderDetailsModal(true);
                }}
              >
                <FaInfoCircle size={14} />
              </button>
              <button
                className={`p-1.5 rounded-md transition-colors duration-200 hover:scale-105 transform
                  ${item.POSStatus === "hold"
                    ? darkMode
                      ? 'bg-green-600 text-white hover:bg-green-700'
                      : 'bg-green-100 text-green-600 hover:bg-green-200'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                onClick={() => handlePay(item)}
                disabled={item.POSStatus !== "hold"}
              >
                <FaMoneyBillWave size={14} />
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <span className={`px-3 py-1 rounded-full text-xs font-medium tracking-wide
            ${item.POSStatus === "dispatched"
              ? "bg-green-100 text-green-700 border border-green-200"
              : item.POSStatus === "hold"
                ? "bg-yellow-100 text-yellow-700 border border-yellow-200"
                : "bg-red-100 text-red-700 border border-red-200"}`}
          >
            {item.POSStatus.charAt(0).toUpperCase() + item.POSStatus.slice(1)}
          </span>
          <span className="px-3 py-1 rounded-full text-xs font-medium tracking-wide bg-blue-100 text-blue-700 border border-blue-200">
            {item.OrderType}
          </span>
        </div>
      </div>

      <div className={`h-24 overflow-y-auto mb-3 text-sm`}>
        {item.orderdata &&
          item.orderdata.map((obj, i) => (
            <div key={i} className="flex justify-between mb-1">
              <span>{obj.ItemName}</span>
              <span>{obj.Quantity}x</span>
            </div>
          ))}
      </div>
      <div className="flex justify-between items-center mb-3">
        <span className="font-semibold">Total Bill:</span>
        <span className="text-red-500 font-bold">Rs. {item.OrderAmount}</span>
      </div>

      <div className="flex justify-between space-x-2">
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300 bg-blue-500 text-white`}
          onClick={() => handleFeedback(item)}
        >
          Feedback
        </button>
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300 bg-red-500 text-white`}
          onClick={() => handleComplain(item)}
        >
          Complain
        </button>
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300 
    ${!item.RiderName
              ? 'bg-yellow-500 text-white hover:bg-yellow-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
          onClick={() => handleAssignRider(item)}
          disabled={!!item.RiderName}
        >
          Assign Rider
        </button>
      </div>

    </div>
  ));

  const NoDataView = () => (
    <div className={`flex flex-col items-center justify-center h-64 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
      <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 20a8 8 0 100-16 8 8 0 000 16z" />
      </svg>
      <h3 className="text-xl font-semibold mb-2">No Orders Found</h3>
      <p className="text-sm opacity-75">There are currently no orders to display.</p>
    </div>
  );

  const GridView = () => (

    <>
      {
        searchedOrders && searchedOrders.length > 0 ? (<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {searchedOrders.map((item: Root2) => (
            <OrderItem key={item.ID} item={item} />
          ))}
        </div>) : (
          <NoDataView />
        )}

      {searchedOrders && searchedOrders.length > 0 && <Pagination />}
    </>
  );

  const TabularView = () => (
    <>
      {searchedOrders && searchedOrders.length > 0 ? (
        <div className="overflow-x-auto">
          <table className={`w-full text-sm ${darkMode ? 'text-white' : 'text-gray-800'} border-collapse`}
            aria-label="Orders table">
            <thead>
              <tr className={`${darkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">S.No</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Bill No</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Customer/Table</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Contact</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Created By</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Order Date</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Delivery Date</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Elapsed</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Type</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Rider</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Outlet</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Amount</th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className={`${darkMode ? 'bg-gray-800' : 'bg-white'} divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {searchedOrders.map((item: Root2, index: number) => {
                const orderDate = item.Created ? new Date(item.Created) : null;
                const deliveryDate = item.Delivered_at ? new Date(item.Delivered_at) : null;
                const elapsedTime = orderDate && !isNaN(orderDate.getTime())
                  ? Math.floor((new Date().getTime() - orderDate.getTime()) / (1000 * 60))
                  : null;

                return (
                  <tr key={item.ID} className={`${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} transition-colors duration-200 text-xs`}>
                    <td className="px-2 py-2 whitespace-nowrap">{index + 1}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.code}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.CustomerName || item.TableName || 'N/A'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.CustomerMobile || 'N/A'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.createdBy || 'System'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{orderDate ? orderDate.toLocaleString() : 'N/A'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{deliveryDate ? deliveryDate.toLocaleString() : 'N/A'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{elapsedTime} min</td>
                    <td className="px-2 py-2 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${item.POSStatus === "dispatched" ? "bg-green-100 text-green-800" :
                          item.POSStatus === "hold" ? "bg-yellow-100 text-yellow-800" :
                            "bg-red-100 text-red-800"}`}>
                        {item.POSStatus.charAt(0).toUpperCase() + item.POSStatus.slice(1)}
                      </span>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.OrderType}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.RiderName || 'N/A'}</td>
                    <td className="px-2 py-2 whitespace-nowrap">{item.OutletName || branchName}</td>
                    <td className="px-2 py-2 whitespace-nowrap font-medium">Rs. {item.OrderAmount}</td>
                    <td className="px-2 py-2 whitespace-nowrap">
                      <div className="flex space-x-1">
                        <button onClick={() => { setSelectedOrderDetails(item); setOpenOrderDetailsModal(true); }}
                          className="p-1 rounded bg-blue-100 text-blue-800 hover:bg-blue-200">
                          View
                        </button>
                        <button onClick={() => handlePay(item)}
                          className={`p-1 rounded ${item.POSStatus === "hold" ? 'bg-green-100 text-green-800 hover:bg-green-200' : 'bg-gray-100 text-gray-400'}`}
                          disabled={item.POSStatus !== "hold"}>
                          Pay
                        </button>
                        <button onClick={() => handleFeedback(item)}
                          className="p-1 rounded bg-purple-100 text-purple-800 hover:bg-purple-200">
                          Feedback
                        </button>
                        <button onClick={() => handleComplain(item)}
                          className="p-1 rounded bg-red-100 text-red-800 hover:bg-red-200">
                          Complain
                        </button>
                        <button onClick={() => handleAssignRider(item)}
                          className={`p-1 rounded ${!item.RiderName ? 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200' : 'bg-gray-100 text-gray-400'}`}
                          disabled={!!item.RiderName}>
                          Assign Rider
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <NoDataView />
      )}

      {searchedOrders && searchedOrders.length > 0 && <Pagination />}
    </>
  );




  return (
    <div>
      {viewOrder === 'grid' ? <GridView /> : <TabularView />}
      {isLoading && (
        <div className={`flex justify-center items-center h-64`}>
          <div className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${darkMode ? "text-white" : "text-black"}`}>
            <span className={`!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] ${darkMode ? "text-white dark:text-black" : "text-black"}`}>
              Loading...
            </span>
          </div>
        </div>
      )}
      <OrderDetailsModal
        open={openOrderDetailsModal}
        onClose={() => setOpenOrderDetailsModal(false)}
        orderDetails={selectedOrderDetails}
        fetchOrders={fetchOrders}
        currentPage={currentPage}
        createOrderJson={createOrderJson}
        setIsLoading={setIsLoading}
      />
      <FeedbackModal
        open={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        orderId={selectedOrderId}
        orderDetails={selectedOrderDetails}
      />
      <ComplaintModal
        open={complainModalOpen}
        onClose={() => setComplainModalOpen(false)}
        orderId={selectedOrderId}
        orderDetails={selectedOrderDetails}
      />
      <AssignRiderModal
        open={assignRiderModalOpen}
        onClose={() => setAssignRiderModalOpen(false)}
        orderId={selectedOrderId}
        orderDetails={selectedOrderDetails}
        fetchOrders={fetchOrders}
        currentPage={currentPage}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

const OrderDetailsModal = React.memo(
  ({
    open,
    onClose,
    orderDetails,
    fetchOrders,
    currentPage,
    createOrderJson,
    setIsLoading,
  }: {
    open: boolean;
    onClose: () => void;
    orderDetails: Root2 | null;
    fetchOrders: (page: number) => Promise<void>;
    currentPage: number;
    createOrderJson: (posStatus: string, kdsStatus: string) => OrderDetails;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
    const cart = useSelector((state: RootState) => state.cart);
    const leftHeader = useSelector((state: RootState) => state.leftHeader);
    const customer = useSelector((state: RootState) => state.customer);
    const dispatch = useDispatch();
    const pos_config = useSelector((state: RootState) => state.leftHeader.pos_config);
    const NewBaseService = createNewBaseService(pos_config);

    const convertOrderDetailsToCartOrder = (orderDetails: Root2): CartOrder => {
      if (!orderDetails.orderdata || !Array.isArray(orderDetails.orderdata)) {
        return [];
      }
      return orderDetails.orderdata.map((orderItem: Orderdaum) => {
        const value: Value[] =
          orderItem.options?.map((option: Option) => ({
            name: option.OptionName,
            properties: [
              {
                ID: option.OptionID,
                Name: option.OptionGroupName,
                Price: parseFloat(option.Price),
                OriginalPrice: parseFloat(option.Price),
                Discount: 0,
                IsActive: true,
              },
            ],
            price: parseFloat(option.Price),
            isMultiple: false,
          })) || [];

        const addOns: AddOn[] =
          orderItem.options?.map((option: Option) => ({
            name: option.OptionName,
            price: parseFloat(option.Price),
            quantity: parseInt(option.Quantity),
            objGroupName: option.OptionGroupName,
            optionID: option.OptionID,
          })) || [];

        return {
          itemName: orderItem.ItemName,
          value,
          total: parseFloat(orderItem.Price),
          itemPrice: parseFloat(orderItem.Price),
          addOns,
          batchNumber: orderItem.batchNumber,
          quantity: parseInt(orderItem.Quantity),
          categoryName: orderItem.Category,
          MinimumDelivery: orderItem.minimumDelivery,
          sizeID: orderItem.SizeID,
          itemID: orderItem.ItemID,
          orderTotal: parseFloat(orderItem.Price),
          index: 0,
          itemStatus: orderItem.Status,
          isComingFromAPI: true,
        };
      });
    };

    const handleStatusUpdate = async (type: 'POS' | 'KDS') => {
      if (orderDetails) {
        dispatch(SET_SELECTED_ORDER(orderDetails));
        dispatch(
          SET_CUSTOMER_DATA({
            Name: orderDetails.CustomerName,
            Mobile: orderDetails.CustomerMobile || "",
            Email: "",
            ID: "",
            IsActive: "",
            Address: "",
            City: orderDetails.City,
            MemberPoints: "",
            customerRating: 0,
            otherAddresses: [],
            orders: [],
            complains: [],
            Created: "",
            updatedAt: "",
            Orders: "",
            __v: 0,
          })
        );
        const convertedOrder = convertOrderDetailsToCartOrder(orderDetails);
        dispatch(SET_CART_ORDER(convertedOrder));

        const order = {
          branch: cart.loginData.branchName,
          code: orderDetails.code,
          platform: "POS",
          name: orderDetails.TableName,
          status: type === 'POS' ? posStatus : orderDetails.POSStatus,
          kdsStatus: type === 'KDS' ? kdsStatus : orderDetails.KDSStatus,
          orderType: orderDetails.OrderType,
          area: "Test area",
          tax: orderDetails.TaxAmount,
          voucher: orderDetails.VoucherValue,
          discountamount: orderDetails.Discount,
          orderamount: orderDetails.totalAmount,
          paymenttype: orderDetails.PaymentType,
          paymentRemarks: orderDetails.remarks || "",
          totalamount: orderDetails.OrderAmount,
          taxamount: orderDetails.TaxAmount,
          deliverycharges: 0,
          customerFullName: orderDetails.CustomerName,
          customerPhone: orderDetails.CustomerMobile,
          customerAddress: orderDetails.DeliveryAddress,
          customerCity: orderDetails.City,
          customerLatitude: null,
          customerLongitude: null,
          customerOtherAddresses: null,
          orderdata: orderDetails.orderdata.map(item => ({
            productName: item.ItemName,
            refCode: item.ItemID,
            categoryName: item.Category,
            itemStatus: item.Status || "Pending",
            discountGiven: 0,
            minimumDelivery: item.minimumDelivery,
            itemImage: "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
            options: item.options?.map(opt => ({
              OptionID: opt.OptionID,
              OptionName: opt.OptionName,
              OptionGroupName: opt.OptionGroupName,
              Price: opt.Price,
              Quantity: opt.Quantity,
            })) || [],
            quantity: item.Quantity,
            price: item.Price,
            totalProductPrice: item.Price,
            SizeID: item.SizeID,
          })),
          Remarks: "",
          category: ""
        };

        try {
          await NewBaseService.post('/dynamic/update', {
            collection: 'orders',
            filter: { id: orderDetails.ID },
            data: order
          });
          onClose();
          setIsLoading(true);
          await fetchOrders(currentPage);
          setIsLoading(false);
        } catch (error) {
          console.error(`${type} status update failed:`, error);
        }
      }
    };


    useEffect(() => {
      if (orderDetails) {
        setPosStatus(orderDetails.POSStatus);
        setKdsStatus(orderDetails.KDSStatus);
      }
    }, [orderDetails]);

    const [posStatus, setPosStatus] = useState(orderDetails?.POSStatus || '');
    const [kdsStatus, setKdsStatus] = useState(orderDetails?.KDSStatus || '');

    const posStatusOptions = ['dispatched', 'hold', 'suspended'];
    const kdsStatusOptions = ['Pending', 'Suspended', 'Served'];
    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={`${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"} 
                     md:p-8 p-6 rounded-lg shadow-xl max-w-4xl mx-4 md:mx-auto max-h-[90vh] overflow-y-auto relative`}>
          <button
            onClick={onClose}
            className={`absolute right-2 top-2 p-2 rounded-full hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200`}
          >
            <IoMdClose size={24} />
          </button>
          <div className="flex flex-col space-y-6 mb-8 border-b pb-6">
            <div className="flex justify-between items-center gap-6">
              <div className="flex flex-col">
                <h2 className="text-3xl font-bold tracking-tight">Order Invoice</h2>
                <span className="text-lg opacity-75 mt-1">#{orderDetails?.code}</span>
              </div>

              <div className="flex items-center space-x-6">

                <div className="flex flex-col space-y-2">
                  <label className="text-sm font-medium opacity-75">POS Status</label>
                  <div className="flex items-center gap-2">
                    <select
                      value={posStatus}
                      onChange={(e) => {
                        setPosStatus(e.target.value)
                      }}
                      className={`px-4 py-2 rounded-lg font-medium ${darkMode ? 'bg-gray-700' : 'bg-gray-100'
                        } border ${posStatus === 'dispatched' ? 'border-green-500 text-green-600' :
                          posStatus === 'hold' ? 'border-yellow-500 text-yellow-600' :
                            'border-red-500 text-red-600'
                        }`}
                    >
                      {posStatusOptions.map(status => (
                        <option key={status} value={status} className="font-medium">
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleStatusUpdate('POS')}
                      className="p-2.5 bg-blue-500 hover:bg-blue-600 rounded-lg text-white transition-colors duration-200"
                    >
                      <FaSync className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                {/* KDS Status */}
                {/* <div className="flex flex-col space-y-2">
                  <label className="text-sm font-medium opacity-75">KDS Status</label>
                  <div className="flex items-center gap-2">
                    <select
                      value={kdsStatus}
                      onChange={(e) => setKdsStatus(e.target.value)}
                      className={`px-4 py-2 rounded-lg font-medium ${darkMode ? 'bg-gray-700' : 'bg-gray-100'
                        } border ${kdsStatus === 'Served' ? 'border-green-500 text-green-600' :
                          kdsStatus === 'Pending' ? 'border-yellow-500 text-yellow-600' :
                            'border-red-500 text-red-600'
                        }`}
                    >
                      {kdsStatusOptions.map(status => (
                        <option key={status} value={status} className="font-medium">
                          {status}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleStatusUpdate('KDS')}
                      className="p-2.5 bg-blue-500 hover:bg-blue-600 rounded-lg text-white transition-colors duration-200"
                    >
                      <FaSync className="w-4 h-4" />
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {orderDetails && (
            <div className="grid grid-cols-1 gap-6">
              <div className="grid grid-cols-2 gap-8 border-b pb-4">
                <div>
                  <h3 className="text-lg font-semibold mb-3">Order Information</h3>
                  <div className="space-y-2">
                    <p><span className="font-medium">Date:</span> {orderDetails.Created ? new Date(orderDetails.Created).toLocaleString() : "N/A"}</p>
                    <p><span className="font-medium">Order Type:</span> {orderDetails.OrderType}</p>
                    <p><span className="font-medium">Payment Method:</span> {orderDetails.PaymentType}</p>
                    <p><span className="font-medium">Payment Status:</span> {orderDetails.PaymentType || 'N/A'}</p>
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-3">Customer Details</h3>
                  <div className="space-y-2">
                    <p><span className="font-medium">Name:</span> {orderDetails.CustomerName || 'Walk-in Customer'}</p>
                    <p><span className="font-medium">Phone:</span> {orderDetails.CustomerMobile || 'N/A'}</p>
                    <p><span className="font-medium">Address:</span> {orderDetails.DeliveryAddress || 'N/A'}</p>
                  </div>
                </div>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <tr>
                      <th className="px-4 py-2 text-left">Item</th>
                      <th className="px-4 py-2 text-right">Quantity</th>
                      <th className="px-4 py-2 text-right">Price</th>
                      <th className="px-4 py-2 text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.orderdata?.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className={`border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                          <td className="px-4 py-2">{item.ItemName}</td>
                          <td className="px-4 py-2 text-right">{item.Quantity}</td>
                          <td className="px-4 py-2 text-right">Rs. {item.Price}</td>
                          <td className="px-4 py-2 text-right">Rs. {Number(item.Quantity) * Number(item.Price)}</td>
                        </tr>
                        {item.options?.map((option, optIndex) => (
                          <tr key={`${index}-${optIndex}`} className="text-sm bg-opacity-50">
                            <td className="px-4 py-1 pl-8">+ {option.OptionName}</td>
                            <td className="px-4 py-1 text-right">{option.Quantity}</td>
                            <td className="px-4 py-1 text-right">Rs. {option.Price}</td>
                            <td className="px-4 py-1 text-right">Rs. {Number(option.Price) * Number(option.Quantity)}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="border-t pt-4">
                <div className="flex justify-end">
                  <div className="w-64 space-y-2">
                    <div className="flex justify-between">
                      <span>Subtotal:</span>
                      <span>Rs. {orderDetails.SubTotal}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Tax:</span>
                      <span>Rs. {orderDetails.TaxAmount}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Discount:</span>
                      <span>Rs. {orderDetails.Discount || 0}</span>
                    </div>
                    <div className="flex justify-between font-bold pt-2 border-t">
                      <span>Total:</span>
                      <span>Rs. {orderDetails.OrderAmount}</span>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          )}
        </div>
      </Modal>
    );
  }
);

export default OrdersList;
